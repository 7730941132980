import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/router'
// import axios from 'axios'//引入axios
import '../src/commponents/index.css'

import dataV from '@jiaminghi/data-view'  //导入datav框架
Vue.config.productionTip = false
Vue.use(dataV)  //添加到vue原型里去
Vue.use(ElementUI);
// Vue.prototype.$axios = axios;//把axios挂载到vue上
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')